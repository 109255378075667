@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

h2 {
  @apply text-2xl sm:text-4xl lg:text-5xl font-cursive text-primary;
}

h3 {
  @apply font-cursive text-xl sm:text-3xl text-neutral-700;
}

.heading-padding-y {
  @apply py-2 sm:py-3 lg:py-5;
}

.paragraph-padding-y {
  @apply py-2 sm:py-3;
}

.site-p {
  @apply p-3 sm:p-4;
}

.site-px {
  @apply px-3 sm:px-4;
}

.site-py {
  @apply py-3 sm:py-4;
}

.site-pt {
  @apply pt-3 sm:pt-4;
}

.site-pb {
  @apply pb-3 sm:pb-4;
}

.site-content-py {
  @apply py-3 sm:py-8 xl:py-12;
}

.anchor {
  @apply font-bold text-primary;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track-piece {
  background-color: #fafafa;
}
::-webkit-scrollbar-thumb {
  background-color: #2892a1;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #09485c;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.modal-scrollbar::-webkit-scrollbar-track-piece {
  background-color: rgba(40, 146, 161, 0.1);
}
.modal-scrollbar::-webkit-scrollbar-thumb {
  background-color: #2892a1;
}
.modal-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #09485c;
}
